<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input v-model="query.title" clearable style="width: 150px;" placeholder="项目标题" class="handle-input mr10"></el-input>
				<el-select
				class="mr10"
				    v-model="query.regionItems"
				    multiple
				    collapse-tags
					clearable
				    placeholder="请选择始发地">
				    <el-option
				      v-for="item in regionItems"
				      :key="item.id"
				      :label="item.name"
				      :value="item.id">
				    </el-option>
				</el-select>
				
				<el-select
				class="mr10"
				    v-model="query.regionEndItems"
				    multiple
				    collapse-tags
					clearable
				    placeholder="请选择目的地">
				    <el-option
				      v-for="item in regionItems"
				      :key="item.id"
				      :label="item.name"
				      :value="item.id">
				    </el-option>
				</el-select>
				
				<el-select
				class="mr10" 
				    v-model="query.vehicleItems"
				    multiple
				    collapse-tags
					clearable
				    placeholder="请选择车辆信息">
				    <el-option
				      v-for="item in vehicleItems"
				      :key="item.id"
				      :label="item.name"
				      :value="item.id">
				    </el-option>
				</el-select>
				
				
				<el-select
				class="mr10"
				    v-model="query.typeItems"
				    multiple
				    collapse-tags
					clearable
				    placeholder="请选择货物品类">
				    <el-option
				      v-for="item in typeItems"
				      :key="item.id"
				      :label="item.name"
				      :value="item.id">
				    </el-option>
				</el-select>
				
				<el-select v-model="query.projectType" clearable class="mr10" placeholder="请选择业务类型">
					<el-option label="城配业务" :value="1"></el-option>
					<el-option label="支线业务" :value="2"></el-option>
					<el-option label="干线业务" :value="3"></el-option>
				</el-select>
				
				<el-select placeholder="是否有效" clearable @change="clickSearch" v-model="query.isValid" style="width: 150px;" class="handle-input mr10">
					<el-option label="有效" :value="1"></el-option>
					<el-option label="无效" :value="0"></el-option>
				</el-select>
				<el-button type="primary" icon="el-icon-search" @click="clickSearch">搜索</el-button>
				<el-button type="primary" @click="openDialog">项目发布</el-button>
				<!-- <el-button type="primary" @click="uploadStatement" :disabled="showBtn">上传流水</el-button> -->
			</div>
			<el-table ref="myTable" :data="tableData" border @select="selectChange">
				<el-table-column
						type="selection"
						width="45">
				</el-table-column>
				<el-table-column prop="title" label="项目标题"></el-table-column>
				<!-- <el-table-column prop="unitName" label="发布公司"></el-table-column> -->
				<!-- <el-table-column prop="contactName" label="联系人"></el-table-column> -->
				<el-table-column prop="regionName" label="始发地"></el-table-column>
				<el-table-column prop="regionEndName" label="目的地"></el-table-column>
				<el-table-column prop="vehicleName" label="车辆信息"></el-table-column>
				<el-table-column prop="typeName" label="货物品类"></el-table-column>
				<!-- <el-table-column prop="price" label="项目价格"></el-table-column> -->
				<el-table-column prop="projectType" label="业务类型">
					<template slot-scope="scope">
						<span v-if="scope.row.projectType == 1">城配业务</span>
						<span v-if="scope.row.projectType == 2">支线业务</span>
						<span v-if="scope.row.projectType == 3">干线业务</span>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="loadAddress" label="装货地点"></el-table-column> -->
				<!-- <el-table-column prop="loadStatus" label="装卸类型">
					<template slot-scope="scope">
						<span v-if="scope.row.loadStatus == 1">司机装卸</span>
						<span v-if="scope.row.loadStatus == 2">客户提供装卸</span>
					</template>
				</el-table-column> -->
				<el-table-column label="是否有效">
					<template slot-scope="scope">
						<span v-if="scope.row.isValid == 1">有效</span>
						<span v-if="scope.row.isValid == 0">无效</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="发布时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="openDetailDialog(scope.row.id)">查看</el-button>
						<el-button type="text" @click="editOpenDialig(scope.row.id)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagination">
				<el-pagination background layout="sizes,total, prev, pager, next"
							   :page-sizes="pageSize"
							   @size-change="handleSizeChange"
							   :current-page="query.pageIndex" :page-size="query.pageRows"
				 :total="pageTotal" @current-change="handlePageChange" prev-click="pageChange(1)" next-click="pageChange(2)"></el-pagination>
			</div>
		</div>
		
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
		  <div style="padding: 0 3%;">
			  <el-form ref="form" :model="form" inline :rules="rules" label-width="120px">
				<el-row>
						<el-form-item label="项目标题" prop="title">
						  <el-input v-model="form.title" style="width: 400px;" clearable placeholder="甲方+乙方+时间, 例如: 京东易梦缘2021年9月"></el-input>
						</el-form-item>
				</el-row>
				  
				 <div style="display: flex;margin: 0 auto;">
				     <el-descriptions title="装货信息" :column="1" border style="width: 45%;float: left;" size="medium">
						 <el-descriptions-item label="始发地">
							<el-select class="form-input" v-model="form.regionId" clearable placeholder="请选择始发地">
							  <el-option
								  v-for="item in regionItems"
								  :key="item.id"
								  :label="item.name"
								  :value="item.id">
								</el-option>
							</el-select>					 
						 </el-descriptions-item>
				         <el-descriptions-item label="装货地址">
							<el-input v-model="form.loadAddress" style="width: 100%;" clearable placeholder="请输入装货地址" ></el-input>
						 </el-descriptions-item>
				         <el-descriptions-item label="业务联系人">
							<el-input v-model="form.projectContactName" class="form-input" clearable placeholder="请输入业务联系人" ></el-input> 
						 </el-descriptions-item>
				         <el-descriptions-item label="联系人电话" >
							<el-input v-model="form.projectContactPhone" class="form-input" clearable placeholder="请输入业务联系人电话" ></el-input>
						 </el-descriptions-item>
				         <el-descriptions-item label="业务持续时间">
							  <el-date-picker @change="changeTimes"
							       v-model="times"
							       type="daterange"
							       range-separator="至"
							       start-placeholder="开始日期"
							       end-placeholder="结束日期"
								   value-format="yyyy-MM-dd">
							     </el-date-picker>
						 </el-descriptions-item>
				     </el-descriptions>
				 
					 <div style="margin: 50px auto;"></div>
				 
				     <el-descriptions title="卸货信息" :column="1" border style="width: 45%;float: right;" size="medium">
						 <el-descriptions-item label="目的地">
							<el-select class="form-input" v-model="form.regionEndId" clearable placeholder="请选择目的地">
							  <el-option
								  v-for="item in regionItems"
								  :key="item.id"
								  :label="item.name"
								  :value="item.id">
								</el-option>
							</el-select>					 
						 </el-descriptions-item>
				         <el-descriptions-item label="卸货地址">
							<el-input v-model="form.unloadAddress" style="width: 100%;" clearable placeholder="请输入卸货地址" ></el-input> 
						 </el-descriptions-item>
				         <el-descriptions-item label="装卸货方式">
							<el-select v-model="form.loadStatus" clearable>
								<el-option label="司机装卸" :value="1"></el-option>
								<el-option label="客户提供装卸" :value="2"></el-option>
							</el-select>
				         </el-descriptions-item>
				         <el-descriptions-item label="业务截止时间">
							<el-date-picker
							   v-model="form.projectDeadline"
							   type="date"
							   placeholder="选择日期"
							   value-format="yyyy-MM-dd">
							</el-date-picker>
						 </el-descriptions-item>
				         <el-descriptions-item label="业务类型">
							<el-select v-model="form.projectType" clearable>
								<el-option label="城配业务" :value="1"></el-option>
								<el-option label="支线业务" :value="2"></el-option>
								<el-option label="干线业务" :value="3"></el-option>
							</el-select> 
						 </el-descriptions-item>
				     </el-descriptions>
				 </div>
				 <div style="margin-top: 20px;">
				     <el-descriptions title="货物信息" direction="vertical" :column="4" border size="medium">
				         <el-descriptions-item label="货物类型">
							 <el-select  class="form-input" v-model="form.typeId" clearable placeholder="货物类型">
								  <el-option
									v-for="item in typeItems"
									:key="item.id"
									:label="item.name"
									:value="item.id">
								  </el-option>
							 </el-select>
						 </el-descriptions-item>
						 <el-descriptions-item label="车辆类型">
							<el-select  class="form-input" v-model="form.vehicleId" clearable placeholder="请选择车辆类型">
							  <el-option
								v-for="item in vehicleItems"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							  </el-option>
							</el-select>
						 </el-descriptions-item>
				         <el-descriptions-item label="运费">
							<el-input class="form-input" v-model="form.price"></el-input>
						 </el-descriptions-item>
						 <el-descriptions-item label="运输有无特别要求">
							<el-input class="form-input" v-model="form.remark"></el-input>
						 </el-descriptions-item>
				     </el-descriptions>
				 </div>
				  
				  <el-row style="margin-top: 20px;">
					  <el-form-item label="详细描述" prop="detailDesc">
						<el-input type="textarea" v-model="form.detailDesc" rows="6" style="width: 520px;"></el-input>
					  </el-form-item>
					  
					  <el-form-item label="是否有效" prop="isValid">
						<el-switch v-model="form.isValid"
						 active-text="有效"
						 inactive-text="无效"></el-switch>
					  </el-form-item>
				  </el-row>
			  </el-form>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="formSubmit">确 定</el-button>
		  </span>
		</el-dialog>
		
		
		
		<el-dialog title="详情" :visible.sync="detailDialog" width="80%">
			
			<ProjectDetail :project="project"></ProjectDetail>
			
			<span slot="footer" class="dialog-footer">
				<el-button @click="detailDialog = false">关 闭</el-button>
			</span>
		</el-dialog>
		
		<el-dialog title="上传流水" :visible.sync="statementDialog" width="40%">
			<Upload ref="mySon" v-on:cancel="cancel"></Upload>
		</el-dialog>
		
		
	</div>
</template>

<script>
	import ProjectDetail from './projectDetail.vue';
	import Upload from '../upload.vue';
	export default {
		name:"projectChild",
		data: function() {
			return {
				query: {
					title:'',
					isValid:'',
					regionItems:[],
					regionEndItems:[],
					typeItems:[],
					vehicleItems:[],
					projectType:'',
					inputType:2,
					pageIndex: 1,
					pageRows: 10,
				},
				pageSize:[
				    10,20,30,40,50
				],
				tableData: [],
				pageTotal: 0,
				dialogVisible:false,
				dialogTitle:'',
				form:{
					id:'',
					title:'',
					regionId:'',
					typeId:'',
					vehicleId:'',
					price:'',
					loadAddress:'',
					loadStatus:'',
					isValid:true,
					detailDesc:'',
					projectContactName:'',
					projectContactPhone:'',
					projectTimeRange:'',
					regionEndId:'',
					unloadAddress:'',
					projectDeadline:'',
					projectType:'',
					remark:''
				},
				rules: {
					  title: [{ required: true, message: '请输入项目标题', trigger: 'blur' }],
					  regionId: [{ required: true, message: '请选择服务地区', trigger: 'change' }],
					  typeId: [{ required: true, message: '请选择货物品类', trigger: 'change' }],
					  vehicleId: [{ required: true, message: '请选择车辆信息', trigger: 'change' }],
					  price: [{ required: true, message: '请输入项目价格', trigger: 'blur' }],
					  loadAddress: [{ required: true, message: '请输入装货地址', trigger: 'blur' }],
					  loadStatus: [{ required: true, message: '请选择装卸方式', trigger: 'change' }],
					  isValid: [{ required: true, message: '请选择', trigger: 'change' }],
					  detailDesc: [{ required: true, message: '请输入详细描述', trigger: 'blur' }],
				},
				regionItems:[],
				typeItems:[],
				vehicleItems:[],
				detailDialog:false,
				project:{},
				showBtn:true,
				statementDialog:false,
				times:[],
			}
		},
		components:{
			ProjectDetail,
			Upload,
		},
		created() {
			this.clickSearch();
			this.initRegionItem();
			this.initVehicleItem();
			this.initTypeItem();
		},
		methods: {
			initRegionItem:function(){
				this.HTTP.get('/base/initRegionItem').then(res=>{
					if(res.data.code==1){
						this.regionItems = res.data.rows;
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			initVehicleItem:function(){
				this.HTTP.get('/base/initVehicleItem').then(res=>{
					if(res.data.code==1){	
						this.vehicleItems = res.data.rows;
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			initTypeItem:function(){
				this.HTTP.get('/base/initTypeItem').then(res=>{
					if(res.data.code==1){	
						this.typeItems = res.data.rows;
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			clickSearch: function() {
				this.query.pageIndex = 1;
				this.handleSearch();
			},
            handleSizeChange:function (val) {
				this.query.pageRows = val
                this.handleSearch()
            },
			handleSearch: function() {
				//查询
				this.HTTP.post('/project/list', this.query).then(res => {
					if (res.data.code == 1) {
						this.tableData = res.data.rows
						this.pageTotal = res.data.counts
					}else{
						this.$message.error(res.data.message);
					}
				}).catch(err => {
					console.log(err)
				})
			},
			handlePageChange: function(e) {
				this.query.pageIndex = e
				this.handleSearch()
			},
			pageChange: function(type) {
				if (type == 1) {
					this.query.pageIndex -= 1;
				} else {
					this.query.pageIndex += 1;
				}
				this.handleSearch()
			},
			resetForm:function(){
				this.form={
					id:'',
					title:'',
					regionId:'',
					typeId:'',
					vehicleId:'',
					price:'',
					loadAddress:'',
					loadStatus:'',
					isValid:true,
					detailDesc:'',
					projectContactName:'',
					projectContactPhone:'',
					projectTimeRange:'',
					regionEndId:'',
					unloadAddress:'',
					projectDeadline:'',
					projectType:'',
					remark:''
				},
				this.$nextTick(()=>{
					if(this.$refs['form']!= undefined){
						this.$refs['form'].resetFields();
					}
				})
			},
			changeTimes:function(e){
				if(e){
					this.form.projectTimeRange = e[0]+"~"+e[1];
				}else{
					this.form.projectTimeRange = '';
				}
			},
			openDialog:function(){
				this.resetForm();
				this.dialogTitle = '项目发布';
				this.dialogVisible = true;
			},
			formSubmit:function(){
				this.$refs['form'].validate((valid) => {
					  if (valid) {
						  if(this.form.id){
							 this.HTTP.post('/project/edit',this.form).then(res=>{
								  if(res.data.code==1){
									  this.$message.success('操作成功');
									  this.dialogVisible = false;
									  this.clickSearch();
								  }else{
									  this.$message.error(err);
								  }
							 }).catch(err=>{
								  this.$message.error(err);
							 })
						  }else{
							 this.HTTP.post('/project/input',this.form).then(res=>{
								  if(res.data.code==1){
									  this.$message.success('操作成功');
									  this.dialogVisible = false;
									  this.clickSearch();
								  }else{
									  this.$message.error(err);
								  }
							 }).catch(err=>{
								  this.$message.error(err);
							 })
						  }
					  }
				});
			},
			editOpenDialig:function(id){
				this.resetForm();
				this.HTTP.get('/project/detail?id='+id).then(res=>{
					if(res.data.code==1){
						this.dialogTitle = '修改项目';
						this.dialogVisible = true;
						this.form = res.data.data;
						if(res.data.data.projectTimeRange){
							var arr = res.data.data.projectTimeRange.split("~");
							this.times = arr;
						}
					}else{
						this.$message.error(err);
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			openDetailDialog:function(id){
				this.HTTP.get('/project/detail?id='+id).then(res=>{
					if(res.data.code==1){
						this.detailDialog = true;
						this.project = res.data.data;
					}else{
						this.$message.error(err);
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			uploadStatement:function(){
				let rows = this.$refs.myTable.selection;
				if(rows.length!=1){
					this.$message.error('请选择一条数据进行后续操作');
					return false;
				}
				let id = rows[0].id;
				let req  = {
					foreignKey:id,
					inputType:1
				}
				this.statementDialog = true;
				this.$nextTick(() => {
				        this.$refs.mySon.initReq(req);
				 })
			},
			selectChange:function(e){
				if(e.length>0){
					this.showBtn = false;
				}else if(e.length<=0){
					this.showBtn = true;
				}
			},
			cancel:function(e){
				this.statementDialog = false;
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
				  .then(_ => {
					done();
				  })
				  .catch(_ => {});
			}
		}
	}
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
		margin-bottom: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}
	
	.info-div {
		display: flex;
		justify-content: space-around;
		margin-top: 5px;
		font-size: 13px;
	}
	.info-span1 {
		width: 230px;
		font-size: 15px;
	}
	
	.info-span2 {
		width: 230px;
		font-size: 15px;
	}
	
	.info-title {
		margin-right: 4px;
		color: #000000;
	}
	.form-input{
		width: 215px;
	}
</style>
