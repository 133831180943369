<template>
    <div style="padding: 1%;">
		 <el-tabs type="border-card" v-model="active">
		   <el-tab-pane label="项目" name="first">
			   <ProjecetChild></ProjecetChild>
		   </el-tab-pane>
		   <!-- <el-tab-pane label="运力" name="second">
			   <CapacityChild></CapacityChild>
		   </el-tab-pane>
		   <el-tab-pane label="配套" name="third">
			   <AssortsChild></AssortsChild>
		   </el-tab-pane> -->
		 </el-tabs>
    </div>
</template>
<script>
	import ProjecetChild from './children/projectChild.vue';
	// import CapacityChild from './children/capacityChild.vue';
	// import AssortsChild from './children/assortsChild.vue';
    export default {
    	name:'myPush',
		props:['activeName'],
        data() {
    		return {
				active:'first',
			}
        },
		components:{
			ProjecetChild,
			// CapacityChild,
			// AssortsChild,
		},
        created() {
			let activeName = this.$props.activeName;
			if(activeName){
				this.active = activeName;
			}
        },
        methods: {
        }
    };
</script>

<style scoped>
	.grey-line .el-row{
		border-bottom: 1px solid #dcdfe6;
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}

    .userInfo_list .grid-content {
        line-height: 20px;
    }

    .userInfo_list .lost-title {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
    }

    .userInfo_list .title-content {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
        margin-left: 5px;
    }

    .grid-content {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center !important;
    }

    .complaint-title {
        align-items: center;
        margin-right: 5px;
        font-size: 16px;
        display: flex;
		font-weight: bolder;
		color: #1f2f3d;
        white-space: nowrap;
    }

    .title-content {
        display: flex;
        align-items: center;
    }
</style>
