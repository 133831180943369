<template>
    <div   style="padding: 0 3%">
        <el-row class="project_list">
			<div class="grid-content">
				<div class="lost-title">{{project.title}}</div>
			</div>
        </el-row>
		
		<div style="display: flex;margin: 10px auto;">
		    <el-descriptions title="装货信息" :column="1" border style="width: 45%;float: left;" size="medium">
				 <el-descriptions-item label="始发地">
					{{project.regionName}}				 
				 </el-descriptions-item>
		        <el-descriptions-item label="装货地址">
					{{project.loadAddress}}
				 </el-descriptions-item>
		        <el-descriptions-item label="业务联系人">
					{{project.projectContactName}}
				 </el-descriptions-item>
		        <el-descriptions-item label="联系人电话" >
					{{project.projectContactPhone}}
				 </el-descriptions-item>
		        <el-descriptions-item label="业务持续时间">
					{{project.projectTimeRange}}
				 </el-descriptions-item>
		    </el-descriptions>
			<div style="margin: 50px auto;"></div>
			<el-descriptions title="卸货信息" :column="1" border style="width: 45%;float: right;" size="medium">
				 <el-descriptions-item label="目的地">
					{{project.regionEndName}}		 
				 </el-descriptions-item>
			    <el-descriptions-item label="卸货地址">
					{{project.unloadAddress}}
				 </el-descriptions-item>
			    <el-descriptions-item label="装卸货方式">
					<span v-if="project.loadStatus==1">司机装卸</span>
					<span v-if="project.loadStatus==2">客户提供装卸</span>
			    </el-descriptions-item>
			    <el-descriptions-item label="业务截止时间">
					{{project.projectDeadline}}
				 </el-descriptions-item>
			    <el-descriptions-item label="业务类型">
					<span v-if="project.projectType==1">城配业务</span>
					<span v-if="project.projectType==2">支线业务</span>
					<span v-if="project.projectType==3">干线业务</span>
				 </el-descriptions-item>
			</el-descriptions>
		</div>
		 
		<div style="margin-top: 20px;">
		    <el-descriptions title="货物信息" direction="vertical" :column="4" border size="medium">
		        <el-descriptions-item label="货物类型">
					{{project.typeName}}
				</el-descriptions-item>
				<el-descriptions-item label="车辆类型">
					{{project.vehicleName}}
				</el-descriptions-item>
		        <el-descriptions-item label="运费">
					{{project.price}}
				</el-descriptions-item>
				<el-descriptions-item label="运输有无特别要求">
					{{project.remark}}
				</el-descriptions-item>
		    </el-descriptions>
		</div>
		
		<div class="grey-line">
			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">状态:</div>
						<div class="title-content">
							<span v-if="project.isValid">有效</span>
							<span v-if="!project.isValid">无效</span>
						</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">合同关联:</div>
						<div class="title-content">
							<span v-if="project.contractId">已关联</span>
							<span v-if="!project.contractId">未关联</span>
						</div>
					</div>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col :span="8">
					<div class="grid-content">
						<div class="complaint-title">发布类型:</div>
						<div class="title-content">
							<span v-if="project.inputType==1">平台发布</span>
							<span v-if="project.inputType==2">用户发布</span>
						</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="grid-content">
						<div class="complaint-title">发布公司:</div>
						<div class="title-content">{{project.unitName}}</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="grid-content">
						<div class="complaint-title">发布人:</div>
						<div class="title-content">{{project.contactName}}</div>
					</div>
				</el-col>
			</el-row>
			
			<div style="width: 100%;border: 1px solid #d8d8d8;height: 300px;margin-top: 10px;">
				<div style="margin-top: 1%;margin-left: 1%;">详细描述</div>
				<el-input type="textarea" v-model="project.detailDesc" :rows="12" resize="none" readonly="readonly" 
				style="margin-top: 1%;margin-left: 1%;width: 98%;"></el-input>
			</div>
		</div>
    </div>
</template>
<script>
    export default {
    	name:'projectDetail',
        props: ['project'],
        data() {
    		return {

			}
        },
        created() {
        },
        methods: {
        }
    };
</script>

<style scoped>
	.grey-line .el-row{
		border-bottom: 1px solid #dcdfe6;
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}

    .project_list .grid-content {
        line-height: 20px;
    }

    .project_list .lost-title {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
    }

    .project_list .title-content {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
        margin-left: 5px;
    }

    .grid-content {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center !important;
    }

    .complaint-title {
        align-items: center;
        margin-right: 5px;
        font-size: 16px;
        display: flex;
		font-weight: bolder;
		color: #1f2f3d;
        white-space: nowrap;
    }

    .title-content {
        display: flex;
        align-items: center;
    }
</style>
