<template>
    <div   style="padding: 0 3%">
        <el-form :model="tempForm" ref="tempForm" label-width="80px" style="margin-top: 5%;">
        	<el-form-item label="文件类型">
        		<!-- <el-radio-group v-model="tempForm.reckonType">
        		    <el-radio :label="1" border>点位结算</el-radio>
        		    <el-radio :label="2" border>件数结算</el-radio>
        		    <el-radio :label="3" border>吨位结算</el-radio>
        		    <el-radio :label="4" border>公里结算</el-radio>
        		</el-radio-group> -->
				<p>至2022年1月19日起已更改为标准账单格式模板，</p>
				<p>此模板为结账、申请贷款、金融服务统一标准模板。</p>
				<p>请选择统一标准模板进行上传</p>
        	</el-form-item>
        	<el-form-item label="文件选择">
        		<div class="uploadDiv">
        		<el-upload ref="upload"
        		  class="upload-demo"
        		  action=""
        		  :http-request="handlePreviewByExcel"
        		  :on-remove="handleRemove"
        		  :file-list="fileList"
        		  :limit="1"
        		  :show-file-list="true"
        		  :auto-upload="false"
        		  list-type="text"
        		  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'>
        		  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        		  <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，单次只能上传一个文件</div>
        		</el-upload>
        		</div>
        	</el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-top: 10%;margin-left: 39%;">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="submitUpload">上 传</el-button>
        </div>
			
			
			
			<el-dialog  title="上传结果" :visible.sync="uploadResultDialog" width="25%">
				<el-form :model="resultForm" ref="resultForm" label-width="80px">
					<el-row>
						<el-col :span="8">
							<div class="grid-content">
								<div class="complaint-title">新增条目:</div>
								<div class="title-content">{{resultForm.insCounts}}</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="grid-content">
								<div class="complaint-title">错误条目:</div>
								<div class="title-content">{{resultForm.errCounts}}</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="grid-content">
								<div class="complaint-title">更新条目:</div>
								<div class="title-content">{{resultForm.existsCounts}}</div>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<div class="grid-content">
								<div class="complaint-title">新增项:</div>
								<div class="title-content">{{resultForm.insItems}}</div>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<div class="grid-content">
								<div class="complaint-title">错误项:</div>
								<div class="title-content">{{resultForm.errItems}}</div>
							</div>
						</el-col>
					</el-row>
					
					<el-row>
						<el-col :span="24">
							<div class="grid-content">
								<div class="complaint-title">更新项:</div>
								<div class="title-content">{{resultForm.existsItems}}</div>
							</div>
						</el-col>
					</el-row>
					<div class="el-upload__tip">
						新增项：表格行数据成功上传服务器<br>
						错误项：表格行数据未填写完整<br>
						更新项：服务器已存在，则根据表格数据更新服务器数据<br>
						新增项，错误项和更新项的数字表示表格序号列</div>
				</el-form>
				<span slot="footer" class="dialog-footer">
				  <el-button @click="uploadResultDialog = false">关 闭</el-button>
				</span>
			</el-dialog>
			
			
    </div>
</template>
<script>
    export default {
    	name:'upload',
        data() {
    		return {
				tempForm:{
				},
				fileList:[],
				uploadResultDialog:false,
				resultForm:{
					insCounts:'',
					insItems:'',
					errCounts:'',
					errItems:'',
					existsCounts:'',
					existsItems:'',
				},
				inputType:'',
				foreignKey:'',
			}
        },
        created() {
        },
        methods: {
			initReq:function(req){
				this.inputType = req.inputType;
				this.foreignKey = req.foreignKey;
			},
			handlePreviewByExcel:function(file){
				const loading = this.$loading({
				  lock: true,
				  text: 'Loading',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				let formData = new FormData();
				formData.append('file',file.file);
				formData.append('inputType',this.inputType);
				formData.append('foreignKey',this.foreignKey);
				this.HTTP.upload('/file/uploadStatement',formData).then(res=>{
					if(res.data.code==1){
						this.$refs.upload.clearFiles();
						this.$message({message:'上传成功',type:'success'});
						this.resultForm = res.data.data;
						this.uploadResultDialog = true;
					}else{
						this.$message.error(res.data.message);
					}
					loading.close();
				}).catch(err=>{
					loading.close();
					this.$message.error(err);
				})
			},
			handleRemove:function(file){
				console.log(this.fileList);
			},
			submitUpload:function(){
				this.$refs.upload.submit();
			},
			cancel:function(){
				this.$emit('cancel',0);
			}
        }
    };
</script>

<style scoped>
	.grey-line .el-row{
		border-bottom: 1px solid #dcdfe6;
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}

    .userInfo_list .grid-content {
        line-height: 20px;
    }

    .userInfo_list .lost-title {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
    }

    .userInfo_list .title-content {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
        margin-left: 5px;
    }

    .grid-content {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center !important;
    }

    .complaint-title {
        align-items: center;
        margin-right: 5px;
        font-size: 16px;
        display: flex;
		font-weight: bolder;
		color: #1f2f3d;
        white-space: nowrap;
    }

    .title-content {
        display: flex;
        align-items: center;
    }
</style>
